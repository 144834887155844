var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000"},model:{value:(_vm.isShowRef),callback:function ($$v) {_vm.isShowRef=$$v},expression:"isShowRef"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('reference_value'))+" - "+_vm._s(_vm.name)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"me-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddNewCheckRef = true}}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('add_checkref'))+" ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.checkref_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit =item;_vm.isEditCheckRef =true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.isDelete = true;_vm.checkref_id = item.checkref_id}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('update:is-show-ref',false)}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)],1),_c('AddNewCheckRef',{attrs:{"checklist-id":_vm.id},on:{"onAdd":_vm.getCheckRefList},model:{value:(_vm.isAddNewCheckRef),callback:function ($$v) {_vm.isAddNewCheckRef=$$v},expression:"isAddNewCheckRef"}}),_c('EditCheckRef',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":_vm.getCheckRefList},model:{value:(_vm.isEditCheckRef),callback:function ($$v) {_vm.isEditCheckRef=$$v},expression:"isEditCheckRef"}}),_c('RemoveCheckRef',{attrs:{"id":_vm.checkref_id},on:{"onDelete":_vm.getCheckRefList},model:{value:(_vm.isDelete),callback:function ($$v) {_vm.isDelete=$$v},expression:"isDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }