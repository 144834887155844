import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const checkRefList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checking/checkreflist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get check ref list error: ', err)

      return []
    })

  return response
}
const checkRefAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checking/checkref',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('aff check ref error: ', err)

      return {}
    })

  return response
}
const checkRefUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checking/checkref',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update check ref error: ', err)

      return {}
    })

  return response
}
const checkRefRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'checking/checkref',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('delete check ref error: ', err)

      return {}
    })

  return response
}

export default {
  checkRefList, checkRefAdd, checkRefUpdate, checkRefRemove,
}
