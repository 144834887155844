<template>
  <div>
    <v-dialog
      v-model="isEditCheckRef"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_checkref') }}
        </v-card-title>
        <v-form
          ref="formEditCheckRef"
          @submit.prevent="updateCheckRef"
        >
          <v-card-text>
            <v-textarea
              v-model="dataEditLocal.checkref_name"
              outlined
              dense
              :rules="[required]"
              rows="3"
              :label="$t('reference_value')"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-check-ref', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import checkRef from '@/api/examination/checkRef'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCheckRef',
    event: 'update:is-add-new-check-ref',
  },
  props: {
    isEditCheckRef: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditCheckRef = ref(null)
    const loading = ref(false)
    const dataEditLocal = ref({})

    const { checkRefUpdate } = checkRef

    const updateCheckRef = () => {
      const isFormValid = formEditCheckRef.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkRefUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-check-ref', false)
        loading.value = false
        emit('onUpdate')
      })
    }
    watch(() => props.dataEdit, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(value))
      }
    })

    return {
      required,
      loading,
      formEditCheckRef,
      dataEditLocal,
      updateCheckRef,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
