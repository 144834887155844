<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Check-list'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link>
        <span>{{ dataCheck.checking_name }}</span> <StatusBlock
          class="ml-2"
          :status="dataCheck.checking_status_id"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-if="!loading && dataCheck.checking_type_id != 1"
          color="primary"
          @click="checking_id = dataCheck.checking_id ; orderNumber = data_list.length+1; isAddNewCheckList = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_checklist') }}
        </v-btn>
      </v-card-title>
      <v-row class="px-5">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p><span class="font-weight-semibold"> {{ $t('code') }}  : </span>{{ dataCheck.checking_code }}</p>
          <p>
            <span class="font-weight-semibold"> {{ $t('examination_set') }}  : </span><CheckSetType
              class="mb-1"
              :type="dataCheck.checking_set_id"
            />
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p><span class="font-weight-semibold"> {{ $t('name')+$t('examination_list') }}  : </span> {{ dataCheck.checking_name }}</p>
          <p><span class="font-weight-semibold"> {{ $t('doctor_fee') }}  : </span> {{ dataCheck.checking_fee }}</p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p>
            <span class="font-weight-semibold"> {{ $t('type') }}  : </span> <CheckType
              class="mb-1"
              :type="dataCheck.checking_type_id"
            />
          </p>
          <p>
            <span class="font-weight-semibold"> {{ $t('examination_fee') }}  : </span> {{ dataCheck.checking_cost }}
          </p>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>#</th>
              <th>
                {{ $t("sort") }}
              </th>
              <th>
                {{ $t("examination_code") }}
              </th>
              <th>
                {{ $t("examination_list") }}
              </th>

              <th class="text-right">
                {{ $t("examination_fee") }}
              </th>
              <th width="250px">
                {{ $t("reference_value") }}
              </th>
              <th class="text-center">
                {{ $t("option") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data_list"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.checklist_sort }}</td>
              <td>{{ item.checklist_code }}</td>
              <td>{{ item.checklist_name }}</td>
              <td class="text-right">
                {{ item.checklist_cost }}
              </td>
              <td>
                <ul class="my-0">
                  <li
                    v-for="(i, index1) in item.checkref"
                    :key="index1"
                  >
                    {{ i.checkref_name }}
                  </li>
                </ul>
              </td>
              <td class="text-center">
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      :disabled="dataCheck.checking_type_id == 1"
                      @click="checklist_id = item.checklist_id ; checklist_name = item.checklist_name;isShowRef = true"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiFormatListNumbered }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("reference_value")
                  }}</span>
                </v-tooltip>
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      @click="dataEdit = item;isEditCheckList = true"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("edit") }}</span>
                </v-tooltip>
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :disabled="dataCheck.checking_set_id == 1"
                      color="primary"
                      v-bind="attrs"
                      @click="checklist_id = item.checklist_id; isDelete = true"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("delete") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <AddNewCheckList
      v-model="isAddNewCheckList"
      :order-number="orderNumber"
      :checking-id="checking_id"
      @onAdd="getCheckList"
    />
    <EditCheckList
      v-model="isEditCheckList"
      :data-edit="dataEdit"
      @onUpdate="getCheckList"
    />
    <RemoveCheckList
      :id="checklist_id"
      v-model="isDelete"
      @onDelete="getCheckList"
    />
    <CheckRef
      :id="checklist_id"
      v-model="isShowRef"
      :name="checklist_name"
    />
  </div>
</template>

<script>
import {
  mdiArrowLeftBold, mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiFormatListNumbered,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import check from '@/api/examination/check'
import CheckSetType from '@/components/basicComponents/CheckSetType.vue'
import CheckType from '@/components/basicComponents/CheckType.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import checkDetail from '@/api/examination/checkDetail'
import AddNewCheckList from './AddNewCheckList.vue'
import EditCheckList from './EditCheckList.vue'
import RemoveCheckList from './RemoveCheckList.vue'
import CheckRef from './CheckRef.vue'

export default {
  components: {
    CheckSetType,
    CheckType,
    StatusBlock,
    AddNewCheckList,
    EditCheckList,
    RemoveCheckList,
    CheckRef,
  },
  setup() {
    const data_list = ref([])
    const dataCheck = ref({})
    const loading = ref(true)

    const orderNumber = ref(0)
    const checking_id = ref('')

    const isAddNewCheckList = ref(false)

    const isEditCheckList = ref()
    const dataEdit = ref({})

    const isDelete = ref(false)
    const checklist_id = ref('')

    const isShowRef = ref(false)
    const checklist_name = ref('')

    const { checkGet } = check

    checkGet(router.currentRoute.params.id).then(res => {
      dataCheck.value = res
      getCheckList()
    })

    const { checkDetailList } = checkDetail

    const getCheckList = () => {
      loading.value = true
      checkDetailList({
        checking_id: router.currentRoute.params.id,
      }).then(res => {
        data_list.value = res
        loading.value = false
      })
    }

    watch(isShowRef, value => {
      if (!value) {
        getCheckList()
      }
    })

    return {
      isShowRef,
      checklist_name,
      checklist_id,
      isDelete,
      isEditCheckList,
      dataEdit,
      checking_id,
      orderNumber,
      isAddNewCheckList,
      data_list,
      loading,
      dataCheck,
      getCheckList,
      icons: {
        mdiFormatListNumbered,

        mdiArrowLeftBold,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCheck,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
