<template>
  <v-chip
    :color="type == 1?'info':'primary'"
    :class="type==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
    small
  >
    {{ type == 1 ? $t('general') : $t('check_lab') }}
  </v-chip>
</template>

<script>

export default {
  props: { type: String },
  setup() {
    return {}
  },
}
</script>
