<template>
  <div>
    <v-dialog
      v-model="isAddNewCheckRef"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_checkref') }}
        </v-card-title>
        <v-form
          ref="formAddCheckRef"
          @submit.prevent="createCheckRef"
        >
          <v-card-text>
            <v-textarea
              v-model="checkref_name"
              outlined
              dense
              :rules="[required]"
              rows="3"
              :label="$t('reference_value')"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-check-ref', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import checkRef from '@/api/examination/checkRef'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCheckRef',
    event: 'update:is-add-new-check-ref',
  },
  props: {
    isAddNewCheckRef: {
      type: Boolean,
      default: false,
    },
    checklistId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const formAddCheckRef = ref(null)
    const loading = ref(false)
    const checkref_name = ref('')

    const { checkRefAdd } = checkRef

    const createCheckRef = () => {
      const isFormValid = formAddCheckRef.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkRefAdd({
        checklist_id: props.checklistId,
        checkref_name: checkref_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-check-ref', false)
        loading.value = false
        emit('onAdd')
      })
    }
    watch(() => props.isAddNewCheckRef, value => {
      if (value) {
        checkref_name.value = ''
      }
    })

    return {
      required,
      loading,
      formAddCheckRef,
      checkref_name,
      createCheckRef,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
