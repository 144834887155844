<template>
  <div>
    <v-dialog
      v-model="isShowRef"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('reference_value') }} - {{ name }} <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="me-2"
            @click="isAddNewCheckRef = true"
          >
            <v-icon
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon> {{ $t('add_checkref') }}
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="columns"
          :items="dataTableList"
          :options.sync="options"
          :loading="loading"
          disable-sort
          hide-default-footer
          :loading-text="$t('data_loading')"
          :no-data-text="$t('no_information')"
        >
          <template v-slot:[`item.checkref_id`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="dataEdit =item;isEditCheckRef =true"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="isDelete = true;checkref_id = item.checkref_id"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-show-ref',false)"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewCheckRef
      v-model="isAddNewCheckRef"
      :checklist-id="id"
      @onAdd="getCheckRefList"
    />
    <EditCheckRef
      v-model="isEditCheckRef"
      :data-edit="dataEdit"
      @onUpdate="getCheckRefList"
    />
    <RemoveCheckRef
      :id="checkref_id"
      v-model="isDelete"
      @onDelete="getCheckRefList"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiPlus, mdiClose, mdiPencilOutline, mdiDeleteOutline,
} from '@mdi/js'
import checkRef from '@/api/examination/checkRef'
import { i18n } from '@/plugins/i18n'
import AddNewCheckRef from './AddNewCheckRef.vue'
import EditCheckRef from './EditCheckRef.vue'
import RemoveCheckRef from './RemoveCheckRef.vue'

export default {
  components: {
    AddNewCheckRef,
    EditCheckRef,
    RemoveCheckRef,
  },
  model: {
    prop: 'isShowRef',
    event: 'update:is-show-ref',
  },
  props: {
    isShowRef: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(false)
    const columns = ref([
      {
        text: '#',
        align: 'start',
        sortable: false,
        value: 'checkref_id',
      },
      {
        text: i18n.t('reference_value'),
        value: 'checkref_name',
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        align: 'center',
      },
    ])

    const isAddNewCheckRef = ref(false)
    const checklist_id = ref('')

    const isEditCheckRef = ref(false)
    const dataEdit = ref({})

    const isDelete = ref(false)
    const checkref_id = ref('')

    const { checkRefList } = checkRef

    const getCheckRefList = () => {
      loading.value = true
      checkRefList({
        checklist_id: props.id,
      }).then(res => {
        dataTableList.value = res
        loading.value = false
      })
    }

    watch(() => props.isShowRef, val => {
      if (val) {
        checklist_id.value = props.id
        getCheckRefList()
      }
    })

    return {
      isDelete,
      checkref_id,
      isEditCheckRef,
      dataEdit,
      isAddNewCheckRef,
      options,
      dataTableList,
      loading,
      columns,
      getCheckRefList,
      icons: {
        mdiPlus,
        mdiClose,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
