<template>
  <div>
    <v-dialog
      v-model="isAddNewCheckList"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_checklist') }}
        </v-card-title>
        <v-form
          ref="formAddCheckList"
          @submit.prevent="createCheckList"
        >
          <v-card-text>
            <v-text-field
              v-model="checklist_sort"
              outlined
              :label="$t('order_no')"
              dense
              type="number"
            ></v-text-field>

            <v-text-field
              v-model="checklist_code"
              outlined
              :rules="[required]"
              class="mt-3"
              dense
              :label="$t('code')"
            ></v-text-field>
            <v-text-field
              v-model="checklist_name"
              outlined
              :rules="[required]"
              :label="$t('list')"
              class="mt-3"
              dense
            ></v-text-field>
            <v-text-field
              v-model="checklist_cost"
              :rules="[required]"
              outlined
              :label="$t('examination_fee')"
              class="mt-3"
              dense
              type="number"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-check-list',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import checkDetail from '@/api/examination/checkDetail'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCheckList',
    event: 'update:is-add-new-check-list',
  },
  props: {
    isAddNewCheckList: {
      type: Boolean,
      default: false,
    },
    orderNumber: {
      type: Number,
      default: 0,
    },
    checkingId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const formAddCheckList = ref(null)
    const checklist_sort = ref('')
    const checklist_code = ref('')
    const checklist_name = ref('')
    const checklist_cost = ref('0')

    const { checkDetailAdd } = checkDetail

    const createCheckList = () => {
      const isFormValid = formAddCheckList.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkDetailAdd({
        checking_id: props.checkingId,
        checklist_code: checklist_code.value,
        checklist_name: checklist_name.value,
        checklist_sort: checklist_sort.value,
        checklist_cost: checklist_cost.value,
        lang: localStorage.getItem('locale'),
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-check-list', false)
        loading.value = false
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewCheckList, () => {
      checklist_sort.value = props.orderNumber
      checklist_code.value = ''
      checklist_name.value = ''
      checklist_cost.value = '0'
    })

    return {
      checklist_name,
      checklist_sort,
      checklist_code,
      checklist_cost,
      loading,
      createCheckList,
      formAddCheckList,
      required,
    }
  },
}
</script>
