<template>
  <v-chip
    :color="type == 1?'error':type == 2?'warning':'success'"
    :class="type==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`:type==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
    small
  >
    {{ type == 1 ? $t('single') : type == 2 ? $t('series') :$t('general') }}
  </v-chip>
</template>

<script>

export default {
  props: { type: String },
  setup() {
    return {

    }
  },
}
</script>
