import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const checkDetailList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checking/checklistlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get check detail list error: ', err)

      return []
    })

  return response
}

const checkDetailAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checking/checklist',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add check detail  error: ', err)

      return {}
    })

  return response
}
const checkDetailUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checking/checklist',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update check detail  error: ', err)

      return {}
    })

  return response
}

const checkDetailRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'checking/checklist',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('delete check detail error: ', err)

      return {}
    })

  return response
}
export default {
  checkDetailList, checkDetailAdd, checkDetailUpdate, checkDetailRemove,
}
