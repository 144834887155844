import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const checkList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checking/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get check list error: ', err)

      return {}
    })

  return response
}
const checkGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'checking',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('get check error: ', err)

      return {}
    })

  return response
}
const checkAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checking',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add check error: ', err)

      return {}
    })

  return response
}
const checkUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checking',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update check error: ', err)

      return {}
    })

  return response
}
const checkStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checking/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update status check error: ', err)

      return {}
    })

  return response
}
const checkImportFile = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checking/import',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('import file check error: ', err)

      return {}
    })

  return response
}
export default {
  checkList,
  checkGet,
  checkAdd,
  checkUpdate,
  checkStatusUpdate,
  checkImportFile,
}
